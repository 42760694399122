<template>
  <div class="mains">
    <el-dialog
      :title="machineSet.dialogTitle"
      :visible.sync="machineSet.dialogVisible"
      width="1412px"
      class="dialog"
    >
      <div class="machine_search">
        <div class="machine_search_list">
          <div class="search_title">{{ $t("Machine.Workshop") }}</div>
          <div class="search_selected">
            <el-select v-model="workshop" @change="changeWorkshop">
              <el-option :label="$t('Machine.All')" value="0" />
              <el-option
                :label="item.name"
                v-model="item.id"
                v-for="item in workshopList"
                :key="item.id"
              />
            </el-select>
          </div>
        </div>
        <div class="machine_search_list">
          <div class="search_title">{{ $t("AddMachine.GroupSimple") }}</div>
          <div class="search_selected">
            <el-select v-model="group" @change="changeGroup">
              <el-option :label="$t('Machine.All')" value="0" />
              <el-option
                :label="item.name"
                v-model="item.id"
                v-for="item in groupList"
                :key="item.id"
              />
            </el-select>
          </div>
        </div>
        <div class="machine_search_list" style="position: relative">
          <div class="search_title">{{ $t("Machine.Equipment") }}</div>
          <div class="search_selected" style="position: relative">
            <input
              type="text"
              :placeholder="$t('Machine.EquipmentName')"
              class="Manual_search_left"
              v-model="machine"
              @blur="blueIpt"
              @focus="focusIpt"
            />
            <img
              src="../../assets/image/clearable.png"
              class="clear"
              @mousedown.stop="cleareMachine"
              v-show="machine && isClear"
            />
            <div class="spm_size" v-show="machineArray.length > 0"></div>
            <div class="search_dia" v-show="machineArray.length > 0">
              <div
                class="search_dia_list"
                v-for="item of machineArray"
                :key="item.id"
              >
                <div class="title-info" @click="selectName(item.name)">
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="search_selected">
              <el-select v-model="machine" filterable remote clearable :placeholder="$t('Machine.EquipmentName')" size="mini" :remote-method="getMachineName">
                <el-option v-for="item in machineArray" :key="item.id" :label="item.name" :value="item.name"/>
              </el-select>
            </div> -->
        </div>
        <div class="machine_search_list">
          <div class="search_title">{{ $t("AddMachine.EquipmentNumber") }}</div>
          <div class="search_selected series">
            <el-input
              :label="$t('Machine.Serial')"
              v-model="series"
              :placeholder="$t('AddMachine.EquipmentNumberInput')"
              clearable
            />
          </div>
        </div>
        <div class="machine_search_btn" style="margin-left: 30px">
          <div
            class="search_btn"
            @click="Search"
            :style="{ background: isBlueSearch ? '#3660DD' : '#4875ef' }"
          >
            {{ $t("Machine.Search") }}
          </div>
        </div>
      </div>
      <div class="table_content">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          :header-cell-style="{ background: '#dde0e8' }"
          highlight-current-row
          style="width: 100%"
          :row-key="row_key"
          :expand-row-keys="expandRowKeys"
          @expand-change="expandChange"
        >
          <el-table-column width="10" align="center" />
          <el-table-column type="expand" min-width="10%">
            <template slot-scope="props">
              <el-table
                ref="multipleTable"
                :data="props.row.devices"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="{ background: '#dde0e8' }"
              >
                <el-table-column width="80" align="center" />
                <el-table-column
                  :label="$t('AddMachine.EquipmentName')"
                  prop="name"
                  min-width="22%"
                />
                <el-table-column
                  :label="$t('AddMachine.GroupName')"
                  prop="group_name"
                  min-width="22%"
                />
                <el-table-column
                  :label="$t('AddMachine.EquipmentModel')"
                  prop="product_model"
                  min-width="22%"
                />
                <el-table-column
                  :label="$t('AddMachine.EquipmentNumber')"
                  prop="number"
                  min-width="22%"
                />
                <el-table-column
                  :label="$t('AddMachine.Enable')"
                  min-width="22%"
                  align="left"
                >
                  <template slot-scope="scope">
                    <el-switch
                      v-model="scope.row.status"
                      :active-value="1"
                      :inactive-value="0"
                  /></template>
                </el-table-column>
                <el-table-column
                  :label="$t('AddMachine.Image')"
                  prop="alarm_high"
                  min-width="15%"
                  align="left"
                >
                  <template slot-scope="scope">
                    <el-popover
                      placement="top-start"
                      trigger="hover"
                      popper-class="area_popper"
                    >
                      <a
                        :href="scope.row.image"
                        target="_blank"
                        title="查看最大化图片"
                        ><img
                          :src="scope.row.image"
                          style="width: 200px; height: 200px"
                      /></a>
                      <img
                        v-if="scope.row.image != ''"
                        slot="reference"
                        :src="scope.row.image"
                        class="machineImage"
                        style="margin-left: -10px"
                      />
                    </el-popover>
                    <img
                      v-if="scope.row.image == ''"
                      src="../../assets/image/mainDefault.png"
                      class="machineImage"
                      style="margin-left: -10px"
                    />
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>
          <el-table-column
            min-width="20%"
            :label="$t('AddMachine.InjectionWorkshop')"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.name }}</div>
            </template>
          </el-table-column>

          <el-table-column width="20" align="center" />
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <div
          class="btn-normal pan-btn blue-btn"
          style="margin-right: 0px; background: #4875ef"
          @click="confireShow"
          :style="{ background: isBlue ? '#3660DD' : '#4875ef' }"
        >
          {{ $t("Login.OK") }}
        </div>
        <div
          class="btn-normal pan-btn tiffany-btn"
          @click="machineSet.dialogVisible = false"
          style="background: #767e95"
          :style="{ background: isGray ? '#798397' : '#767e95' }"
        >
          {{ $t("AddMachine.Cancel") }}
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import { getErrorMoldList } from '../../api/warning'
import { getMachine, listSearch, changeMachine } from "@/api/machine";
import "../../style/table.less";
export default {
  name: "Group",
  data() {
    return {
      repeat: true,
      workshop: "0",
      group: "0",
      machine: "",
      Startime: "",
      Endtime: "",
      series: "",
      isGray: false,
      isClear: false,
      isBlue: false,
      isBlueSearch: false,
      machineList: [],
      workshopList: [],
      groupList: [],
      machineItem: [],
      machineArray: [],
      machineForm: {
        // 表单数据
        group: "",
        machine_image: "",
        name: "",
        serial_number: "",
        workshop: "",
      },
      workShop: {
        machineCode: "",
      },
      hideUpload: false,
      machineSet: {
        dialogStatus: "Add", // 弹窗操作状态
        dialogTitle: this.$t("AddMachine.AddEquipment"), // 弹窗标题
        dialogVisible: false, // 弹窗状态
        codes: false,
        machineImage: "", // 上传的设备图片
      },
      tableData: [
        // { id: '0', time: '注塑车间', error: '热电偶', zone: '005', detail: [{ current_temp: '120', setting_temp: '150', status: 'Run', output: '1.2', alarm_high: '', alarm_low: '1', current: '0' }] },
        // { id: '1', time: '注塑车间', error: '热电偶', zone: '005', detail: [{ current_temp: '120', setting_temp: '150', status: 'Run', output: '1.2', alarm_high: '', alarm_low: '1', current: '1' }] },
        // { id: '2', time: '注塑车间', error: '热电偶', zone: '005', detail: [{ current_temp: '120', setting_temp: '150', status: 'Run', output: '1.2', alarm_high: '', alarm_low: '1', current: '1' }] },
        // { id: '3', time: '注塑车间', error: '热电偶', zone: '005', detail: [{ current_temp: '120', setting_temp: '150', status: 'Run', output: '1.2', alarm_high: '', alarm_low: '1', current: '1' }] },
        // { id: '4', time: '注塑车间', error: '热电偶', zone: '005', detail: [{ current_temp: '120', setting_temp: '150', status: 'Run', output: '1.2', alarm_high: '', alarm_low: '1', current: '1' }] },
        // { id: '5', time: '注塑车间', error: '热电偶', zone: '005', detail: [{ current_temp: '120', setting_temp: '150', status: 'Run', output: '1.2', alarm_high: '', alarm_low: '1', current: '1' }] }
      ],
      expandRowKeys: [],
      total: 0,
    };
  },
  created() {
    this.getMachine("");
    this.listSearch("init");
  },
  watch: {
    workshop(value) {
      if (parseInt(value) === 0) {
        this.workshopList = [];
        this.listSearch();
      }
    },
    machine(val) {
      if (val) {
        this.getMachineName(val);
      } else {
        this.machineArray = [];
      }
    },
    "$i18n.locale"(val) {
      this.changeLan();
      this.changeWorkshopLan();
    },
  },
  methods: {
    changeLan() {
      if (this.tableData.length > 0) {
        if (
          this.tableData[0].name === "默认车间" ||
          this.tableData[0].name === "Default Workshop"
        ) {
          this.$set(this.tableData[0], "name", this.$t("Tip.Default"));
        }
      }
    },
    changeWorkshopLan() {
      if (this.workshopList.length > 0) {
        if (
          this.workshopList[0].name === "默认车间" ||
          this.workshopList[0].name === "Default Workshop"
        ) {
          this.$set(this.workshopList[0], "name", this.$t("Tip.Default"));
        }
      }
    },

    blueIpt() {
      setTimeout(() => {
        this.machineArray = [];
        this.isClear = false;
      }, 20);
    },
    focusIpt() {
      setTimeout(() => {
        this.isClear = true;
      }, 20);
    },
    cleareMachine() {
      this.machine = "";
    },
    selectName(val) {
      this.machine = val;
      setTimeout(() => {
        this.machineArray = [];
      }, 20);
    },
    confireShow() {
      this.isBlue = true;
      setTimeout(() => {
        this.isBlue = false;
      }, 500);
      const ary = [];
      for (var i = 0; i < this.tableData.length; i++) {
        for (var j = 0; j < this.tableData[i].devices.length; j++) {
          if (this.tableData[i].devices[j].status === 1) {
            ary.push(this.tableData[i].devices[j].id);
          }
        }
      }
      const params = {};
      params.id = ary.join(",");
      params.mode = "show";
      const token = localStorage.getItem("token");
      const lang = localStorage.getItem("language");
      if (!params.id) {
        if (!this.repeat) {
          return;
        }
        this.repeat = false;
        setTimeout(() => {
          this.repeat = true;
        }, 2000);
        this.$message({
          showClose: true,
          message: this.$t("Tip.EditSuccess"),
          type: "warning",
          duration: 1000,
        });

        return;
      }
      changeMachine(params, token, lang).then((res) => {
        if (res.code === 200) {
          this.$emit("AddSuccess");
          this.machineSet.dialogVisible = false;
          this.$message({
            showClose: true,
            message: this.$t("Tip.AddSuccess"),
            type: "success",
            duration: 1000,
          });
        } else {
        }
      });
    },
    Search() {
      this.isBlueSearch = true;
      setTimeout(() => {
        this.isBlueSearch = false;
      }, 500);
      const params = {};
      params.name = this.machine;
      params.group_id = parseInt(this.group) === 0 ? "" : this.group;
      params.wid = parseInt(this.workshop) === 0 ? "" : this.group;
      params.number = this.series;
      params.is_hidden = 1;
      params.token = localStorage.getItem("token");
      params.language = localStorage.getItem("language");
      getMachine(params).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.count;
        this.expandRowKeys = [];
        if (this.machine || (!this.machine && this.group)) {
          for (var i = 0; i < this.tableData.length; i++) {
            this.expandRowKeys.push(this.tableData[i].id);
          }
        }
        this.machineArray = [];
      });
    },
    getMachineName(value) {
      this.machineArray = [];
      if (value !== "") {
        const ary = this.machineItem;
        if (ary) {
          for (var j = 0; j < ary.length; j++) {
            if (ary[j].name.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
              this.machineArray.push(ary[j]);
            }
          }
        }
      }
    },
    changeGroup() {
      this.machineItem = [];
      this.machineArray = [];
      this.machine = "";

      if (parseInt(this.group) === 0) {
        for (var i = 0; i < this.workInfo.length; i++) {
          for (var j = 0; j < this.workInfo[i].device_group.length; j++) {
            for (
              var k = 0;
              k < this.workInfo[i].device_group[j].devices.length;
              k++
            ) {
              this.machineItem.push(
                this.workInfo[i].device_group[j].devices[k]
              );
            }
          }
        }
      } else {
        for (var m = 0; m < this.workInfo.length; m++) {
          for (var n = 0; n < this.workInfo[m].device_group.length; n++) {
            if (
              parseInt(this.group) ===
              parseInt(this.workInfo[m].device_group[n].id)
            ) {
              for (
                var s = 0;
                s < this.workInfo[m].device_group[n].devices.length;
                s++
              ) {
                this.machineItem.push(
                  this.workInfo[m].device_group[n].devices[s]
                );
              }
            }
          }
        }
      }
    },
    changeWorkshop() {
      this.groupList = [];
      this.machineArray = [];
      this.machine = "";
      this.group = "";
      for (var i = 0; i < this.workInfo.length; i++) {
        if (parseInt(this.workshop) === this.workInfo[i].id) {
          this.groupList = this.workInfo[i].device_group;
        }
      }
      this.group = this.groupList.length > 0 ? this.groupList[0].id : 1;
      this.changeGroup();
    },
    async listSearch() {
      const params = {};
      params.query_level = 2;
      const token = localStorage.getItem("token");
      await listSearch(params, token).then((res) => {
        this.workInfo = res.data;

        for (var i = 0; i < this.workInfo.length; i++) {
          const workshopItem = {
            id: "",
            name: "",
          };
          workshopItem.id = this.workInfo[i].id;
          workshopItem.name = this.workInfo[i].name;
          this.workshopList.push(workshopItem);
          for (var j = 0; j < this.workInfo[i].device_group.length; j++) {
            this.groupList.push(this.workInfo[i].device_group[j]);
          }
        }
        this.changeWorkshopLan();
        this.changeGroup();
      });
      this.group = "0";
    },
    getGroup() {},
    showMachine() {
      this.machineSet.dialogVisible = true;
      this.expandRowKeys = [];
      this.machine = "";
      this.workshop = "0";
      this.group = "0";
      this.series = "";
      this.getMachine("");
    },
    getMachine() {
      const params = {};
      params.name = "";
      params.group = "";
      params.workshop = "";
      params.number = "";
      params.is_hidden = 1;
      params.token = localStorage.getItem("token");
      getMachine(params).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.count;
        this.changeLan();
      });
    },
    row_key(row) {
      return row.id;
    },
    expandChange(row, rowList) {
      this.expandRowKeys = [];
      rowList.forEach((item) => {
        this.expandRowKeys.push(item.id);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.spm_size {
  position: absolute;
  top: 39px;
  left: 35px;
  z-index: 15;
  width: 0;
  z-index: 10001;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 6px solid #fff;
}
.clear {
  width: 14px;
  height: 14px;
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 10px;
  font-weight: bold;
}
.Manual_search_left {
  -webkit-appearance: none;
  height: 32px !important;
  line-height: 32px !important;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
.search_dia_list {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  box-sizing: border-box;
  cursor: pointer;
  background-color: #fff;
  text-align: left;
}
.search_dia {
  width: 188px;
  max-height: 220px;
  border-radius: 4px;
  position: absolute;
  padding: 5px 0 0 0;
  left: 0px;
  top: 45px;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 9;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%) !important;
}
.search_dia::after {
  top: 1px;
  margin-left: -6px;
  border-top-width: 0;
  border-bottom-color: #fff;
}

.search_dia::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 4px;
  height: 2px;
  background-color: #fff;
  cursor: pointer;
}
/*定义滚动条轨道 内阴影+圆角*/
.search_dia::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px rgba(255, 255, 255, 0.5);
  border-radius: 0px;
  background-color: #fff;
}

/*定义滑块 内阴影+圆角*/
.search_dia::-webkit-scrollbar-thumb {
  border-radius: 0px;
  box-shadow: inset 0 0 0px rgba(255, 255, 255, 0.5);
  background-color: #eee;
}
@media (max-width: 1367px) {
  .search_dia {
    width: 168px;
  }
  .machine_search_list {
    flex: 3 !important;
  }
  .machine_search_btn {
    margin-left: 0px !important;
  }
  .Manual_search_left {
    width: 168px;
  }
  .dialog /deep/ .el-dialog {
    border-radius: 20px !important;
    margin-top: 10vh !important;
    height: 580px !important;
    width: 1212px !important;
  }
  .dialog /deep/ .el-input__suffix {
    right: 5px !important;
  }
  .table_content {
    height: 330px !important;
  }
}
@media (min-width: 1367px) and (max-width: 1441px) {
  .search_dia {
    width: 168px;
  }
  .machine_search_list {
    flex: 3 !important;
  }
  .machine_search_btn {
    margin-left: 0px !important;
  }
  .machine_search /deep/ .el-input__inner {
    width: 168px !important;
  }
  .Manual_search_left {
    width: 168px;
  }
  .dialog /deep/ .el-dialog {
    border-radius: 20px !important;
    margin-top: 12vh !important;
    height: 580px !important;
    width: 1212px !important;
  }
  .dialog /deep/ .el-input__suffix {
    right: 5px !important;
  }
  .table_content {
    height: 330px !important;
  }
}
@media (min-width: 1441px) and (max-width: 1919px) {
  .search_dia {
    left: 0px;
    width: 188px;
  }
  .dialog /deep/ .el-dialog {
    margin-top: 12vh !important;
  }
}
.dialog /deep/ .el-dialog {
  border-radius: 20px !important;
  height: 680px;
  background-color: #e7e9ee;
}
.dialogs /deep/ .el-dialog {
  border-radius: 20px !important;
}
.dialog /deep/ .el-dialog__body {
  padding: 0px 0px 10px 0px !important;
}

.btn-normal {
  width: 70px;
  height: 32px;
  border-radius: 20px;
  font-size: 13px;
  line-height: 34px;
  font-weight: 400;
  text-align: center;
  color: #fff;
  float: right;
  margin-left: 10px;
  cursor: pointer;
}
.dialog /deep/ .el-dialog__footer {
  padding: 40px 20px 15px !important;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}
.OpeImg {
  display: flex;
  justify-content: center;
}
.app-wrapper /deep/ .el-table__empty-text {
  line-height: 20px !important;
  font-weight: bold;
  font-size: 16px;
  color: #909399;
}
.el-input--mini .el-input__inner {
  height: 32px !important;
  line-height: 32px !important;
}

@media screen and (max-width: 1367px) {
  .table-container {
    height: calc(100vh - 365px);
  }
}
.table-container {
  overflow-y: auto;
  width: 100%;
}
@media (min-width: 1368px) and (max-width: 1600px) {
  .table-container {
    height: calc(100vh - 365px);
  }
}

@media screen and (min-width: 1601px) {
  .table-container {
    height: calc(100vh - 165px);
  }
  .app-wrapper .search-panel .search-box /deep/ .el-input__inner {
    width: 148px !important;
    font-size: 13px !important;
  }
}
.app-wrapper .from-box .label[data-v-dad8d5b0] {
  line-height: 30px;
}
.dialog /deep/ .from-box .add-panel .add-icon-box {
  left: 10px !important;
}
.dialog /deep/ .label {
  text-align: left;
}
.dialog /deep/ .el-dialog__headerbtn {
  font-size: 30px !important;
  top: 10px;
}
.dialogs /deep/ .el-dialog__headerbtn {
  font-size: 0px !important;
  top: 10px;
}
.dialog /deep/ .el-dialog__header {
  padding: 20px 30px 10px;
  text-align: left;
}
.dialogs /deep/ .el-dialog__header {
  text-align: left;
}
.dialog /deep/ .el-dialog__title {
  font-weight: bold;
  font-size: 20px;
}

.OperationImg {
  width: 24px;
  height: 24px;
  float: left;
  cursor: pointer;
}
// .table-container /deep/ .el-icon-arrow-right {
//     margin-left: -8px;
//     margin-top: -8px;
//     font-size: 18px !important;
// }
.app-wrapper /deep/ .pan-btn {
  width: 70px !important;
  height: 32px !important;
  border-radius: 20px !important;
  font-size: 13px !important;
  line-height: 34px !important;
  font-weight: 400 !important;
  text-align: center !important;
  color: #fff !important;
  padding: 0px !important;
  cursor: pointer;
  // background-color:#4875ef !important;
}
.app-wrapper {
  .search-panel {
    .search-box {
      display: inline-block;
      margin-right: 10px;
      float: right;
      .label {
        font-weight: 400;
        color: #4d4f5c;
        margin-bottom: 10px;
      }
      // /deep/.el-select {
      //   width: 170px;
      // }
      /deep/.el-input__inner {
        height: 32px;
        line-height: 32px;
      }
      /deep/.el-input__icon {
        line-height: 32px;
      }
    }
  }
  .from-box {
    display: flex;
    justify-content: space-between;

    .label {
      font-size: 18px;
      font-weight: 400;
      color: #4d4f5c;
    }
    .add-panel {
      display: flex;
    }
    .left-box {
      flex-basis: 60%;
      /deep/.el-input {
        width: 380px;
      }
      /deep/.el-input__inner {
        height: 32px;
        line-height: 32px;
      }
    }
    .right-box {
      flex-basis: 40%;
      //text-align: center;
      /deep/.el-input {
        width: 164px;
      }
      /deep/.el-input__inner {
        height: 32px;
        line-height: 32px;
      }
      .flex-img {
        .flex-img__image {
          width: 146px;
          height: 146px;
          border-radius: 6px;
          display: block;
        }
        .image-uploader /deep/.el-upload {
          background-color: #fbfdff;
          border: 1px dashed #d9d9d9;
          border-radius: 6px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
        }
        .image-uploader /deep/.el-upload:hover {
          border-color: #4875ef;
        }
        .image-uploader-icon {
          font-size: 28px;
          color: #8c939d;
          width: 146px;
          height: 146px;
          line-height: 146px;
          text-align: center;
        }
        .image {
          width: 146px;
          height: 146px;
          display: block;
        }
      }
    }
    /deep/.hide .el-upload--picture-card {
      display: none;
    }
  }
  .pagination-container {
    margin: 25px;
  }
  .table-container {
    // min-height: calc(100vh - 288.5px);
    .el-table::before {
      height: 0px;
    }
    /deep/.el-table__body-wrapper {
      // max-height: calc(100vh - 445px);
      overflow-y: auto;
    }
    /deep/.el-table__empty-text {
      margin-top: 0;
      .empty_text {
        // font-weight: 700;
        // color: #929292;
        // margin-top: -25px;
      }
    }
  }
  .el-table {
    .machineImage {
      width: 28px;
      height: 28px;
      border-radius: 5px;
      margin-left: 1px !important;
    }
    .pan-btn {
      margin: 0px;
      padding: 6px 22px;
      cursor: pointer;
    }
  }
  // /deep/.el-dialog {
  //   margin-bottom: 5vh;
  // }
}
.data_empty /deep/ .empty_text {
  margin-top: 0px !important;
}
.data_empty {
  padding-top: 90px;
}
.app-wrapper .from-box .right-box .flex-img .image-uploader /deep/ .el-upload {
  margin-top: 5px;
}
.search-box img {
  width: 32px;
  height: 32px;
  float: left;
  margin-right: 0px;
  cursor: pointer;
  margin-top: 10px;
}
.dialog /deep/.el-dialog__body .copy_input {
  width: 480px !important;
}
.table-container /deep/ .el-table .el-table__empty-text {
  display: none;
}
.mains {
  .dialog {
    height: 100%;

    .table_content {
      height: 430px;
      overflow: auto;
      /deep/ .el-table__body-wrapper {
        height: auto;
        overflow-y: auto;
        overflow-x: hidden;
        background-color: #ebecee;
      }
      /deep/ .el-table tr {
        // background-color: #ebecee !important;
        cursor: pointer;
      }
      /deep/ .el-table--fit {
        overflow-y: auto;
        overflow-x: hidden;
        background-color: #ebecee;
      }
      /deep/ .el-table__expanded-cell .el-table--fit {
        height: auto;
        overflow-y: auto;
        overflow-x: hidden;
      }
      /deep/ .el-table tr {
        background-color: #ebecee !important;
      }
    }
    .machine_title {
      padding: 22px 20px;
      color: #414141;
      font-size: 24px;
      font-weight: 700;
      text-align: left;
    }
    .machine_search {
      display: flex;
      flex-direction: row;
      padding: 20px 30px;
      .machine_search_list {
        flex: 4;
        .search_title {
          float: left;
          text-align: left;
          height: 32px;
          line-height: 32px;
          margin: 10px 10px 10px 0;
          color: #4d4f5c;
          font-size: 18px;
        }
        .series {
          /deep/ .el-input__suffix {
            top: -7px !important;
          }
        }
        .search_selected {
          float: left;
          margin: 10px 10px 10px 0;

          /deep/ .el-date-editor.el-input,
          /deep/ .el-date-editor.el-input__inner {
            width: 100%;
          }
          /deep/ .el-input__inner {
            height: 32px;
            line-height: 32px;
          }
          /deep/ .el-input__icon {
            margin-top: 5px;
            color: #a4afb7;
            font-weight: bold;
          }
          /deep/ .input-wrapper .el-input__icon {
            margin-top: 2px;
          }
          /deep/ .el-select .el-input .el-select__caret.is-reverse {
            margin-top: -3px;
          }
        }
      }
      .machine_search_btn {
        flex: 0.55;
        .search_btn {
          width: 70px;
          height: 32px;
          background: #4875ef;
          font-size: 13px;
          color: #f2f3f6;
          text-align: center;
          line-height: 32px;
          border-radius: 30px;
          margin-top: 10px;
          margin-right: 10px;
          cursor: pointer;
        }
        .edi_color {
          background-color: #767e95;
        }
      }
    }
  }
}
</style>

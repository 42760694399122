<template>
  <div class="app-wrapper">
    <div class="machine_title">{{ $t("Machine.Equipment") }}</div>
    <div class="machine_search">
      <div class="machine_search_list">
        <div class="search_title">{{ $t("Machine.Workshop") }}</div>
        <div class="search_selected">
          <el-select v-model="workshop" @change="changeWorkshop">
            <el-option :label="$t('Machine.All')" value="0" />
            <el-option
              :label="item.name"
              v-model="item.id"
              v-for="item in workshopList"
              :key="item.id"
            />
          </el-select>
        </div>
      </div>
      <div class="machine_search_list">
        <div class="search_title">{{ $t("Machine.EquipmentGroup") }}</div>
        <div class="search_selected">
          <el-select v-model="group" @change="changeGroup">
            <el-option :label="$t('Machine.All')" value="0" />
            <el-option
              :label="item.name"
              v-model="item.id"
              v-for="item in groupList"
              :key="item.id"
            />
          </el-select>
        </div>
      </div>
      <div class="machine_search_list" style="position: relative">
        <div class="search_title">{{ $t("Machine.Equipment") }}</div>
        <div class="search_selected" style="width: 87%">
          <!-- @select="handleSelect" -->
          <el-autocomplete
            class=""
            v-model="machine"
            :fetch-suggestions="querySearch"
            :placeholder="$t('Machine.EquipmentName')"
            style="width: 100%"
            @blur="blueIpt"
            @focus="focusIpt"
          ></el-autocomplete>
          <img
            src="../../assets/image/clearable.png"
            class="clear"
            @mousedown.stop="cleareMachine"
            v-show="machine && isClear"
          />
        </div>
      </div>
      <div class="machine_search_btn">
        <div
          class="search_btn"
          @click="Search"
          :style="{ background: isBlueSearch ? '#3660DD' : '#4875ef' }"
        >
          {{ $t("Machine.Search") }}
        </div>
      </div>
      <div class="machine_search_space"></div>
      <div class="machine_search_btn" @click="edit">
        <div
          class="search_btn edi_color"
          :style="{ background: isGray ? '#252833' : '#767e95' }"
        >
          {{ editText }}
        </div>
      </div>
      <div class="machine_search_btn">
        <div
          class="search_btn"
          style="margin-right: 0px"
          @click="AddMachine"
          :style="{
            opacity: isEdit ? 0.6 : 1,
            background: isBlue ? '#3660DD' : '#4875ef',
          }"
        >
          {{ $t("Machine.Add") }}
        </div>
      </div>
    </div>
    <div class="machine_content">
      <el-col
        v-for="(item, index) in machineList"
        :key="index"
        :lg="{ span: '4-8' }"
        :md="8"
        class="card-panel-col"
      >
        <div
          class="machine_list"
          @click="
            toTab(
              item.serial_number,
              item.status,
              item.serial_number,
              item.name
            )
          "
        >
          <div class="machine_status">
            <div
              class="status_color"
              :style="{
                background: item.status == 0 ? 'rgb(168, 171, 181)' : '#4ecc6b',
              }"
            ></div>
            <div class="status_text">
              {{ item.status == 0 ? $t("Machine.notNet") : $t("Machine.net") }}
            </div>
          </div>
          <div class="machine_info">
            <div class="machine_img">
              <img v-if="item.image" :src="`https://api.yudotmc.com`+item.image" alt="">
              <img v-else src="../../assets/image/组 878@2x.png" alt="" />
            </div>
            <div class="machine_base">
              <div class="machine_name" style="line-height: 45px">
                {{ $t("Machine.Name") }} : {{ item.name }}
              </div>
              <div class="machine_size" style="line-height: 30px">
                {{ $t("Machine.Model") }} : {{ item.product_model }}
              </div>
            </div>
          </div>
          <div class="machine_order">
            {{ $t("AddMachine.EquipmentNumber") }} : {{ item.number }}
          </div>
          <div
            class="machine_cancel"
            v-show="isEdit"
            @click="deleteCard(item.id)"
          ></div>
        </div>
      </el-col>
      <add-machine ref="changeMachine" @AddSuccess="AddSuccess"></add-machine>
    </div>
    <div v-show="noDataTable" class="noData">
      <img src="../../assets/image/emptyData.png" />
      <div class="empty_text">{{ $t("Warning.NoData") }}</div>
    </div>
  </div>
</template>

<script>
import AddMachine from "./addMachine.vue";
import { initMachine, changeMachine, listSearch } from "../../api/machine";
export default {
  name: "Machine",
  components: {
    AddMachine,
  },
  data() {
    return {
      workshop: "0",
      websock: null,
      workInfo: [],
      isGray: false,
      isBlue: false,
      isClear: false,
      isBlueSearch: false,
      noDataTable: false,
      isEdit: false,
      group: "0",
      machine: "",
      editText: "",
      machineList: [],
      workshopList: [],
      groupList: [],
      machineItem: [],
      machineArray: [],
    };
  },
  created() {
    this.initMachine();
    this.initWebSocket();
    this.listSearch("init");
    this.editText = this.$t("Machine.Edit");
  },
  beforeDestroy() {
    this.websock.close();
  },

  watch: {
    workshop(value) {
      if (parseInt(value) === 0) {
        this.workshopList = [];
        this.listSearch();
      }
    },
    machine(val) {
      if (val) {
        this.getMachineName(val);
      } else {
        this.machineArray = [];
      }
    },
    "$i18n.locale"(val) {
      this.changeWorkshopLan();
      if (this.editText === "编辑" || this.editText === "Edit") {
        this.editText = this.$t("Machine.Edit");
      } else if (this.editText === "完成" || this.editText === "Done") {
        this.editText = this.$t("Machine.Done");
      }
    },
  },
  methods: {
    querySearch(queryString, cb) {
      const machineArray = this.machineItem.map((item) => {
        return {
          value: item.name,
          ...item,
        };
      });
      const results = queryString
        ? machineArray.filter(this.createFilter(queryString))
        : machineArray;
      cb(results);
    },
    createFilter(queryString) {
      return (data) => {
        return data.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    },
    changeWorkshopLan() {
      if (this.workshopList.length > 0) {
        if (
          this.workshopList[0].name === "默认车间" ||
          this.workshopList[0].name === "Default Workshop"
        ) {
          this.$set(this.workshopList[0], "name", this.$t("Tip.Default"));
        }
      }
    },
    blueIpt() {
      // this.machineArray = []
      this.isClear = false;
    },
    focusIpt() {
      this.isClear = true;
    },
    cleareMachine() {
      this.machine = "";
    },
    selectName(val) {
      this.machine = val;
      setTimeout(() => {
        this.machineArray = [];
      }, 20);
    },
    getMachineName(value) {
      this.machineArray = [];
      if (value !== "") {
        const ary = this.machineItem;
        if (ary) {
          for (var j = 0; j < ary.length; j++) {
            if (ary[j].name.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
              this.machineArray.push(ary[j]);
            }
          }
        }
      }
    },
    changeGroup() {
      this.machineItem = [];
      this.machineArray = [];
      this.machine = "";

      if (parseInt(this.group) === 0) {
        for (var i = 0; i < this.workInfo.length; i++) {
          for (var j = 0; j < this.workInfo[i].device_group.length; j++) {
            for (
              var k = 0;
              k < this.workInfo[i].device_group[j].devices.length;
              k++
            ) {
              this.machineItem.push(
                this.workInfo[i].device_group[j].devices[k]
              );
            }
          }
        }
      } else {
        for (var m = 0; m < this.workInfo.length; m++) {
          for (var n = 0; n < this.workInfo[m].device_group.length; n++) {
            if (
              parseInt(this.group) ===
              parseInt(this.workInfo[m].device_group[n].id)
            ) {
              for (
                var s = 0;
                s < this.workInfo[m].device_group[n].devices.length;
                s++
              ) {
                this.machineItem.push(
                  this.workInfo[m].device_group[n].devices[s]
                );
              }
            }
          }
        }
      }
    },
    changeWorkshop() {
      this.groupList = [];
      this.machineArray = [];
      this.machine = "";
      this.group = "";
      for (var i = 0; i < this.workInfo.length; i++) {
        if (parseInt(this.workshop) === this.workInfo[i].id) {
          this.groupList = this.workInfo[i].device_group;
        }
      }
      this.group = this.groupList.length > 0 ? this.groupList[0].id : 1;
      this.changeGroup();
    },
    async listSearch() {
      const params = {};
      params.query_level = 2;
      const token = localStorage.getItem("token");
      await listSearch(params, token).then((res) => {
        this.workInfo = res.data;
        for (var i = 0; i < this.workInfo.length; i++) {
          const workshopItem = {
            id: "",
            name: "",
          };
          workshopItem.id = this.workInfo[i].id;
          workshopItem.name = this.workInfo[i].name;
          this.workshopList.push(workshopItem);
          for (var j = 0; j < this.workInfo[i].device_group.length; j++) {
            this.groupList.push(this.workInfo[i].device_group[j]);
          }
        }
        this.changeWorkshopLan();
        this.changeGroup();
      });
      this.group = "0";
    },
    getGroup() {},
    initMachine() {
      const params = {};
      params.token = localStorage.getItem("token");
      params.language = localStorage.getItem("language");
      initMachine(params).then((res) => {
        this.machineList = res.data.list;
        this.noDataTable = res.data.list.length === 0;
      });
    },
    initWebSocket() {
      // 初始化weosocket
      const token = localStorage.getItem("token");
      const lang = localStorage.getItem("language");
      const wsuri =
        "wss://api.yudotmc.com/rtk?token=" + token + "&language=" + lang;
      this.websock = new WebSocket(wsuri);
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
      // this.websock.onsend = this.websocketsend
    },
    websocketonopen() {
      // 连接建立之后执行send方法发送数据
      this.websocketsend();
    },
    websocketonerror() {
      // 连接建立失败重连
    },
    websocketonmessage(e) {
      // 数据接收
      const redata = JSON.parse(e.data);

      if (redata.data.data) {
        this.machineList = redata.data.data.list;
        this.noDataTable = redata.data.data.list.length === 0;
      }
    },
    websocketsend(Data) {
      // 数据发送
      this.websock.send(Data);
    },
    websocketclose(e) {
      // 关闭
    },
    AddSuccess() {
      const params = {
        channel: "list",
        data: {
          wid: 0,
          group_id: 0,
          device_name: "",
          refresh: 1,
        },
      };

      this.websocketsend(JSON.stringify(params));
    },
    async Search() {
      this.isBlueSearch = true;
      setTimeout(() => {
        this.isBlueSearch = false;
      }, 500);
      const loading = this.$loading({
        lock: true,
        text: this.$t("Tip.Loading"),
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const params = {
        channel: "list",
        data: {
          wid: parseInt(this.workshop),
          group_id: parseInt(this.group),
          device_name: this.machine,
          refresh: 0,
        },
      };
      this.machineArray = [];
      await this.websocketsend(JSON.stringify(params));
      loading.close();
    },
    AddMachine() {
      this.isBlue = true;
      setTimeout(() => {
        this.isBlue = false;
      }, 500);
      this.$refs.changeMachine.showMachine();
    },
    toTab(id, status, serial, name) {
      if (this.isEdit) {
        return;
      }
      if (status !== 0) {
        sessionStorage.setItem("currentTab", "Temp");
      }
      if (status === 0) {
        sessionStorage.setItem("currentTab", "Record");
      }
      this.$router.push({
        name: "Tab",
        query: { id: id, status: status, serial_number: serial, name: name },
      });
    },
    deleteCard(id) {
      const params = {};
      params.id = id;
      params.mode = "hidden";
      const token = localStorage.getItem("token");
      changeMachine(params, token).then((res) => {
        const params = {
          channel: "list",
          data: {
            wid: 0,
            group_id: 0,
            device_name: "",
            refresh: 1,
          },
        };

        this.websocketsend(JSON.stringify(params));
      });
    },
    edit() {
      this.isGray = true;
      setTimeout(() => {
        this.isGray = false;
      }, 500);
      this.editText = this.isEdit
        ? this.$t("Machine.Edit")
        : this.$t("Machine.Done");
      this.isEdit = !this.isEdit;
    },
  },
};
</script>

<style lang="less" scoped>
.spm_size {
  position: absolute;
  top: 74px;
  left: 45px;
  z-index: 15;
  width: 0;
  z-index: 10001;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 6px solid #fff;
}
.clear {
  width: 14px;
  height: 14px;
  cursor: pointer;
  position: absolute;
  right: 35px;
  top: 43px;
  font-weight: bold;
}
.Manual_search_left {
  -webkit-appearance: none;
  height: 32px !important;
  line-height: 32px !important;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
.search_dia_list {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  box-sizing: border-box;
  cursor: pointer;
  background-color: #fff;
  text-align: left;
}
.search_dia {
  width: 213px;
  max-height: 220px;
  border-radius: 4px;
  position: absolute;
  padding: 5px 0 0 0;
  left: 0px;
  top: 80px;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 9;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%) !important;
}
.search_dia::after {
  top: 1px;
  margin-left: -6px;
  border-top-width: 0;
  border-bottom-color: #fff;
}

.search_dia::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 4px;
  height: 2px;
  background-color: #fff;
  cursor: pointer;
}
/*定义滚动条轨道 内阴影+圆角*/
.search_dia::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px rgba(255, 255, 255, 0.5);
  border-radius: 0px;
  background-color: #fff;
}

/*定义滑块 内阴影+圆角*/
.search_dia::-webkit-scrollbar-thumb {
  border-radius: 0px;
  box-shadow: inset 0 0 0px rgba(255, 255, 255, 0.5);
  background-color: #eee;
}
@media (max-width: 1367px) {
  .machine_search_list {
    flex: 2.25 !important;
  }
  .clear {
    right: 20px;
    top: 42px;
  }
  .search_dia {
    width: 183px;
  }
}
@media (min-width: 1367px) and (max-width: 1441px) {
  .machine_search_list {
    flex: 1.45 !important;
  }
  .clear {
    right: 20px;
    top: 42px;
  }
  .search_dia {
    width: 163px;
  }
}
@media (min-width: 1441px) and (max-width: 1601px) {
  .clear {
    right: 20px;
    top: 45px;
  }
  .search_dia {
    width: 178px;
  }
}
@media (min-width: 1601px) and (max-width: 1681px) {
  .clear {
    right: 20px;
    top: 45px;
  }
  .search_dia {
    width: 188px;
  }
}
.noData {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 500px;

  position: absolute;
  flex-direction: column;
  left: 0;
  top: 200px;
  .empty_text {
    line-height: 20px !important;
    font-weight: bold;
    font-size: 16px;
    color: #909399;
  }
}
@media only screen and (min-width: 768px) {
  .el-col-md-8 {
    width: 20%;
  }
}
.app-wrapper {
  /deep/ .el-input--mini .el-input__icon {
    margin-top: 0px !important;
  }
  background-color: #ebecee;
  height: 100%;
  /deep/ .el-table__body-wrapper::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 2px;
    height: 2px;
    background-color: #fff;
    cursor: pointer;
  }
  /*定义滚动条轨道 内阴影+圆角*/
  /deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px rgba(255, 255, 255, 0.5);
    border-radius: 0px;
    background-color: #fff;
  }

  /*定义滑块 内阴影+圆角*/
  /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
    border-radius: 0px;
    box-shadow: inset 0 0 0px rgba(255, 255, 255, 0.5);
    background-color: #707070;
  }
  .machine_title {
    padding: 22px 20px;
    color: #414141;
    font-size: 24px;
    font-weight: 700;
    text-align: left;
  }
  .machine_search {
    display: flex;
    flex-direction: row;
    padding: 0px 25px;
    .machine_search_list {
      flex: 1.25;
      .search_title {
        text-align: left;
        color: #4d4f5c;
        font-size: 18px;
      }
      .search_selected {
        float: left;
        margin: 10px 10px 10px 0;
        /deep/ .el-input__inner {
          height: 32px;
          line-height: 32px;
        }
        /deep/ .el-input__icon {
          margin-top: 5px;
          color: #a4afb7;
          font-weight: bold;
        }
        /deep/ .el-select .el-input .el-select__caret.is-reverse {
          margin-top: -3px;
        }
      }
    }
    .machine_search_btn {
      flex: 0.45;
      .search_btn {
        width: 70px;
        height: 31px;
        background: #4875ef;
        font-size: 13px;
        color: #f2f3f6;
        text-align: center;
        line-height: 31px;
        border-radius: 30px;
        margin-top: 34px;
        margin-right: 10px;
        cursor: pointer;
      }
      .edi_color {
        background-color: #767e95;
      }
    }
    .machine_search_space {
      flex: 4;
    }
  }
  .machine_content {
    padding: 15px 0 0px 25px;
    float: left;
    width: 100%;
    height: calc(100vh - 152px);
    overflow: auto;
    .machine_list {
      height: 140px;
      margin-bottom: 20px;
      margin-right: 25px;
      background: #ffffff;
      border-radius: 10px;
      box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.17);
      position: relative;
      cursor: pointer;
      .machine_cancel {
        position: absolute;
        width: 20px;
        height: 20px;
        right: 8px;
        top: 8px;
        background-image: url("../../assets/image/组 877@2x.png");
        background-repeat: no-repeat;
        background-size: cover;
        cursor: pointer;
      }
      .machine_status {
        width: 100%;
        padding: 10px 12px;
        float: left;
        .status_color {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          float: left;
        }
        .status_text {
          float: left;
          font-size: 12px;
          color: #515050;
          margin-left: 5px;
          margin-top: -2px;
          transform: scale(0.8);
        }
      }
      .machine_info {
        width: 100%;
        padding: 0px 12px 10px 12px;
        float: left;
        .machine_img {
          width: 70px;
          height: 70px;
          border-radius: 6px;
          float: left;
          img {
            width: 100%;
            height: 100%;
            float: left;
            border-radius: 6px;
            object-fit: cover;
          }
        }
        .machine_base {
          float: left;
          margin-left: 10px;
          width: calc(100% - 80px);
          text-align: left;
          .machine_name,
          .machine_size {
            height: 35px;
            line-height: 35px;
            color: #323232;
            font-size: 12px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
      .machine_order {
        width: 100%;
        padding: 5px 12px 0px 12px;
        text-align: center;
        color: #a5a5a5;
        transform: scale(0.8);
        float: left;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>
